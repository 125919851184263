import React, { useContext } from 'react'
import uuid from 'react-uuid'
import { Button, ButtonGroup, IconInfo, Modal } from '@veneer/core'
import ConfigContext from 'context/config/ConfigContext'
import { FlexColumn, FlexRow } from 'styles/styles'
import 'styles/global.scss'

export const taskAssignTypeEnum = {
  CREATE: 'create',
  UPDATE: 'update'
}

const AssignPolicyModal = (props) => {
  const { t } = useContext(ConfigContext)
  const { policyName, assignedGroup, updateType, onDone, onMoveToDevices } =
    props

  const key = (subkey: string): string => {
    return t(`task.assignPolicyModal.${subkey}`)
  }

  return (
    <Modal
      onClose={onDone}
      closeOnBlur={false}
      show={true}
      className={'small-task-modal'}
      data-testid={'id-assign-policy-modal'}
      footer={
        <ButtonGroup>
          <Button id={'idDone'} appearance={'secondary'} onClick={onDone}>
            {key('done')}
          </Button>
        </ButtonGroup>
      }
    >
      <FlexRow className={'modalTitleBox'}>
        <IconInfo filled color={'gray7'} size={36} key={uuid()} />
        <h4 className={'marginLeft8'}>
          {updateType === taskAssignTypeEnum.CREATE
            ? key('createTitle')
            : key('updateTitle')}
        </h4>
      </FlexRow>
      <FlexRow>
        <FlexColumn className={'minWidth150'}>{key('policyName')}</FlexColumn>
        <FlexColumn>{policyName}</FlexColumn>
      </FlexRow>
      <FlexRow className={'paddingBottom16'}>
        <FlexColumn className={'minWidth150'}>
          {key('assignedGroup')}
        </FlexColumn>
        <FlexColumn>{assignedGroup}</FlexColumn>
      </FlexRow>
      <FlexRow className={'paddingBottom16'}>
        {updateType === taskAssignTypeEnum.CREATE
          ? key('createDescription')
          : key('updateDescription')}
      </FlexRow>
      <Button
        small
        appearance={'ghost'}
        id={'moveToDevices'}
        onClick={() => {
          onDone()
          onMoveToDevices()
        }}
      >
        {key('moveToDevices')}
      </Button>
    </Modal>
  )
}

export default AssignPolicyModal
