import PoliciesCategories from './PoliciesCategories'
import { Group } from 'utils/Group'

/**
 * getCategories
 *
 * @param attributes policy attributes
 * @param t localization
 * @returns {string} category
 */
export function getCategories(attributes: { name: string }[], t): string {
  const categories = PoliciesCategories.getPolicyCategories([], attributes)
  const category = (key) => t(`task.commonPolicies.categories.${key}`)
  return categories.length > 1
    ? t('task.commonPolicies.more', {
        name: category(categories[0]),
        count: categories.length - 1
      })
    : categories.length
    ? category(categories[0])
    : ''
}

/**
 * Get group name, localized if necessary
 *
 * @param group device group
 * @param t localization
 * @returns {string} group name: localized or user-defined
 */

export function getGroupName(group: Group, t): string {
  return ['All', 'Ungrouped'].includes(group?.name) && group?.builtIn == true
    ? t(`task.group.${group?.name.toLowerCase()}`)
    : group?.name
}

/**
 * Find Predefined group
 *
 * @param groupArray array of Groups
 * @param groupName groupName
 * @returns {Group} group
 */
export function findPredefinedGroup(
  groupArray: Array<Group>,
  groupName: string
) {
  return groupArray?.find(
    (obj) => obj.name === groupName && obj.builtIn == true
  )
}

/**
 * Sort array alphabetically
 *
 * @param items array of objects to sort
 * @param picker object field picker
 * @param order sorting order
 * @returns {Object} same array of objects, now sorted
 */

export function abcSort(
  items: unknown[],
  picker: (any) => unknown,
  order?: string
): unknown[] {
  const comparator = (a, b) => {
    const lowerA = typeof a === 'string' ? a.toLowerCase() : a
    const lowerB = typeof b === 'string' ? b.toLowerCase() : b

    if (lowerA === lowerB) return 0
    else if (lowerA > lowerB) return 1
    else return -1
  }
  const sorter =
    order !== 'descending' // 'ascending' is def order
      ? (valA, valB) => comparator(valA, valB)
      : (valA, valB) => comparator(valB, valA)
  return items.sort((a, b) => sorter(picker(a), picker(b)))
}

/**
 * Fixes outdated WX theme for Veneer v3.105+
 *
 * @param themeProps theme properties
 * @returns corrected theme properties
 */
export function fixTheme(themeProps) {
  let props = themeProps
  // Newer Veneer expects background container object, not just one color as it was before v3.105
  const light = props.customSemantics?.color?.light?.background?.container
  if (light && !light.default) {
    // fix light theme if needed
    props = {
      ...props,
      customSemantics: {
        ...props.customSemantics,
        color: {
          ...props.customSemantics.color,
          light: {
            ...props.customSemantics.color.light,
            background: {
              ...props.customSemantics.color.light.background,
              container: {
                default: '#ffffff',
                hover: '#f5f5f5',
                active: '#f0f0f0'
              }
            }
          }
        }
      }
    }
  }
  const dark = props.customSemantics?.color?.dark?.background?.container
  if (dark && !dark.default) {
    // fix dark theme if needed
    props = {
      ...props,
      customSemantics: {
        ...props.customSemantics,
        color: {
          ...props.customSemantics.color,
          dark: {
            ...props.customSemantics.color.dark,
            background: {
              ...props.customSemantics.color.dark.background,
              container: {
                default: '#292929',
                hover: '#303030',
                active: '#404040'
              }
            }
          }
        }
      }
    }
  }
  return props
}
