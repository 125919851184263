import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  ButtonGroup,
  Button,
  Modal,
  Table,
  Search,
  ColumnIndexTypes
} from '@veneer/core'
import { TableSortBy, SortTypes } from '@veneer/core'
import ConfigContext from 'context/config/ConfigContext'
import ModalContext from 'context/modal/ModalContext'
import TasksContext from 'context/tasks/TasksContext'
import ErrorContext from 'context/tasks/ErrorContext'
import { getCategories, abcSort } from 'utils/utilities'
import { ASCENDING } from 'components/tasks/constants/constant'
import { getDecoratedName } from 'utils/decoratedName'
import 'styles/global.scss'
import Retrievei18nItems from 'utils/Retrievei18nItems'

enum addPolicyEnum {
  POLICY_NAME = 'policyName',
  CATEGORY = 'category'
}

const pageSize = 10
const defSortOrder: SortTypes = ASCENDING
const defSorting: TableSortBy = {
  id: addPolicyEnum.POLICY_NAME,
  type: defSortOrder
}

export const AddPolicy = (props) => {
  const { selectedPolicies, addPolicyToTable, tableList, displayToaster } =
    props
  const { t, isWex } = useContext(ConfigContext)
  const { hideAddPolicies, showAddPolicy } = useContext(ModalContext)
  const { getAllPolicies, policies } = useContext(TasksContext)
  const { error } = useContext(ErrorContext)
  const [addPolicyTableData, setAddPolicyTableData] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [isTableDataSet, setIsTableData] = useState(false)
  const [sorting, setSorting] = useState(defSorting)

  const tableColumns = useMemo(() => {
    const id = 'id'
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id, label: id, index },
      {
        id: addPolicyEnum.POLICY_NAME,
        label: t('task.columnHeader.policyName'),
        sortable: true
      },
      {
        id: addPolicyEnum.CATEGORY,
        label: t('task.columnHeader.category'),
        sortable: true
      }
    ]
  }, [])

  const searchValueLowerCase = useMemo(
    () => searchValue.toLowerCase(),
    [searchValue]
  )

  const filteredData = useMemo(() => {
    if (!(searchValue.length && addPolicyTableData)) return addPolicyTableData
    return addPolicyTableData
      ?.filter((x) => {
        const test = (y) =>
          x.rowConfig[y].toLowerCase().includes(searchValueLowerCase)
        return test(addPolicyEnum.POLICY_NAME) || test(addPolicyEnum.CATEGORY)
      })
      .map((x) => {
        const text = (y) => (
          <div>
            {getDecoratedName(x.rowConfig[y], searchValueLowerCase, isWex)}
          </div>
        )
        return {
          ...x,
          [addPolicyEnum.POLICY_NAME]: text(addPolicyEnum.POLICY_NAME),
          [addPolicyEnum.CATEGORY]: text(addPolicyEnum.CATEGORY)
        }
      })
  }, [searchValue, addPolicyTableData])

  const sortedData = useMemo(
    () =>
      filteredData
        ? abcSort(filteredData, (x) => x.rowConfig[sorting.id], sorting.type)
        : filteredData,
    [filteredData, sorting]
  )

  const checkExist = (policyId) => {
    const isPolicyPresent = tableList?.filter(
      (policy) => policy.id === policyId
    )
    return !!isPolicyPresent?.length
  }

  const checkPolicyChecked = (policyId) => {
    const isPolicyChecked = addPolicyTableData?.find(
      (policy) => policy.id === policyId
    )
    return isPolicyChecked?.rowConfig?.selected
  }

  const getDataForTable = (data) => {
    return data && data.items
      ? data.items.map((policy) => {
          return {
            id: policy.id,
            rowConfig: {
              [addPolicyEnum.POLICY_NAME]: policy.name,
              [addPolicyEnum.CATEGORY]: getCategories(policy.attributes, t),
              policyAttributes: policy.attributes,
              selected: isTableDataSet
                ? checkPolicyChecked(policy.id)
                : checkExist(policy.id)
            },
            [addPolicyEnum.POLICY_NAME]: policy.name,
            [addPolicyEnum.CATEGORY]: getCategories(policy.attributes, t)
          }
        })
      : null
  }

  useEffect(() => {
    if (showAddPolicy) {
      setAddPolicyTableData(null)
      getAllPolicies(displayToaster)
      setSearchValue('')
      setIsTableData(false)
    }
  }, [showAddPolicy])

  useEffect(() => {
    setAddPolicyTableData(getDataForTable(policies))
  }, [policies, tableList])

  const disableAdd = useMemo(() => {
    if (!(addPolicyTableData && tableList)) {
      return true
    }
    const selected = addPolicyTableData.filter((x) => x.rowConfig.selected)
    if (selected.length !== tableList.length) {
      return false
    }
    return selected.every((x) => tableList.find((y) => y.id === x.id))
  }, [addPolicyTableData, tableList])

  useEffect(() => {
    if (!isTableDataSet && addPolicyTableData) {
      setIsTableData(true)
    }
  }, [isTableDataSet, addPolicyTableData])

  const handleRowSelect = (event, rowId) => {
    const found = addPolicyTableData.find((row) => row.id === rowId)
    if (found) {
      found.rowConfig.selected = event.target.checked
      setAddPolicyTableData([...addPolicyTableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    const filteredDataIds = filteredData?.map(({ id }) => id)
    const newTableData = addPolicyTableData.map((row) => {
      if (filteredDataIds?.indexOf(row.id) >= 0) {
        row.rowConfig.selected = checked
      }
      return row
    })
    setAddPolicyTableData(newTableData)
  }

  const getPageSelectionState = React.useCallback(() => {
    const total = addPolicyTableData?.length
    const selected =
      addPolicyTableData?.filter((row) => row?.rowConfig?.selected).length || 0

    if (selected === 0) return 'none'
    if (selected === total) return 'all'
    return 'indeterminated'
  }, [addPolicyTableData])

  const onAddClick = () => {
    const data = getDataForTable(policies)
    const selectedPolicies = data?.filter(
      (row) => row.rowConfig.selected === true
    )
    addPolicyToTable(
      selectedPolicies.map((x) => {
        const {
          id,
          rowConfig: { policyName, policyAttributes }
        } = x
        return { id, policyName, policyAttributes }
      })
    )
  }

  const onCancelClick = () => {
    hideAddPolicies()
  }
  return (
    <div className={'add-policy-wrapper'}>
      <Modal
        id={'add-policy-modal'}
        closeOnBlur={false}
        onClose={() => hideAddPolicies()}
        show={showAddPolicy}
        title={t('task.addPolicy.title')}
        className={'small-task-modal'}
        footer={
          <ButtonGroup>
            <Button
              id={'addButton'}
              onClick={() => onAddClick()}
              disabled={
                selectedPolicies === 0 ||
                (policies?.items?.length === 0 && error) ||
                disableAdd
              }
            >
              {t('common.add')}
            </Button>
            <Button
              id={'cancelButton'}
              appearance={'secondary'}
              onClick={() => onCancelClick()}
            >
              {t('common.cancel')}
            </Button>
          </ButtonGroup>
        }
      >
        <p className={'paddingBottom16'}>{t('task.addPolicy.description')}</p>
        <Search
          id={'policy-search'}
          className={'policyAddSearch'}
          placeholder={t('task.addPolicy.search')}
          value={searchValue}
          onChange={(value) => setSearchValue(value)}
        />
        <Table
          columns={tableColumns}
          data={sortedData || []}
          onSort={(_, sortBy) => setSorting(sortBy)}
          loading={addPolicyTableData === null}
          loadingDataLength={pageSize}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          preferences={{
            width: [
              { columnId: addPolicyEnum.POLICY_NAME, width: 250 },
              { columnId: addPolicyEnum.CATEGORY, width: 150 }
            ],
            sortBy: sorting
          }}
          rowSelector={'multiSelection'}
          rowSelectAllState={getPageSelectionState()}
          className={'app-policy-table-height'}
          i18n={Retrievei18nItems()}
        />
      </Modal>
    </div>
  )
}

export default AddPolicy
