import React, { useContext } from 'react'
import { Button, Modal, ButtonGroup, IconXCircle } from '@veneer/core'
import ErrorContext from 'context/tasks/ErrorContext'
import ConfigContext from 'context/config/ConfigContext'
import { FlexRow } from 'styles/styles'
import 'styles/global.scss'

export const Error = () => {
  const errorContext = useContext(ErrorContext)
  const { error, errorFlag, hideError } = errorContext
  const cContext = useContext(ConfigContext)
  const { t } = cContext
  const hideModalCallback = () => hideError()

  return (
    <Modal
      onClose={hideError}
      show={errorFlag}
      closeOnBlur={false}
      className={'small-task-modal'}
      footer={
        <ButtonGroup>
          <Button onClick={hideModalCallback}>{t('common.ok')}</Button>
        </ButtonGroup>
      }
    >
      <FlexRow className={'modalTitleBox'}>
        <IconXCircle size={36} filled color={'red7'} />
        <h4 className={'marginLeft8'}>{t('errors.title')}</h4>
      </FlexRow>
      <p>
        {error && error.errorMessage
          ? error.errorMessage
          : t('errors.code.other')}
      </p>
    </Modal>
  )
}

export default Error
