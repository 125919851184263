import React, { useMemo, useCallback } from 'react'
import { Scrollbar, TreeView, TreeViewId, TreeViewNode } from '@veneer/core'
import { abcSort } from 'utils/utilities'
import isEqual from 'lodash/isEqual'

type TreeViewComponentProps = {
  settingAlert: boolean
  lastCollectionId: string
  currentCollectionId: string
  handleSelectedCollectionGroup: (id: TreeViewId, value: boolean) => void
  collectionNodes: TreeViewNode[]
  parentId: string
}

const TreeViewComponent: React.FC<TreeViewComponentProps> = React.memo(
  ({
    settingAlert,
    lastCollectionId,
    currentCollectionId,
    handleSelectedCollectionGroup,
    collectionNodes,
    parentId
  }) => {
    const onChange = useCallback(
      (e, id) => {
        handleSelectedCollectionGroup(id, false)
      },
      [handleSelectedCollectionGroup]
    )

    const nodes = useMemo(() => {
      const sortNodes = (nodes: TreeViewNode[]) => {
        const [ungroupedNode, ...otherNodes] = nodes
        abcSort(otherNodes, (node) => node.label)
        return [ungroupedNode, ...otherNodes]
      }

      return collectionNodes.map((node) => ({
        ...node,
        nodes: node.nodes ? sortNodes(node.nodes) : []
      }))
    }, [collectionNodes])

    return (
      parentId && (
        <Scrollbar>
          <TreeView
            defaultExpandedNodes={[parentId]}
            defaultSelectedNodes={[parentId]}
            selectedNodes={[
              (settingAlert ? lastCollectionId : currentCollectionId) || '0'
            ]}
            onChange={onChange}
            nodes={nodes}
          />
        </Scrollbar>
      )
    )
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps)
  }
)

TreeViewComponent.displayName = 'TreeViewComponent'

export default TreeViewComponent
