//Policy attributes
export enum PolicyAttributeEnum {
  Auto_FW_Update = 'auto-fw-update',
  Legacy_Firmware_Upgrade = 'legacy-fw-update',
  Remote_FW_Update = 'remote-fw-update',
  PJL_Password = 'pjl-password',
  Remote_Cfg_Password = 'remote-cfg-password',
  Svc_Access_Code = 'svc-access-code',
  PJL_Access_Commands = 'pjl-command',
  FS_Access_Protocols = 'fs-access-protocol',
  HTTPS_Redirect = 'https-redirect',
  WiFi = 'wifi', // SMB only
  SNMP_V1_V2 = 'snmp-v1-v2',
  SNMP_V3 = 'snmp-v3',
  EWS_Admin_Password = 'ews-password',
  EWS_Admin_Password_New = 'ews-password-new', // to remove (test)
  File_Erase_Mode = 'file-erase',
  Retain_Print_Jobs = 'retain-jobs',
  Control_Panel_Lock = 'ctrl-panel-lock',
  Check_Latest_FW = 'check-latest',
  Svc_Location_Protocol = 'slp',
  IPv4_Multicast = 'ipv4-multicast',
  LLMNR = 'llmnr',
  Ws_Discovery = 'ws-discovery',
  Bonjour = 'bonjour',
  Tcpip_Print = 'tcpip-print',
  Airprint = 'airprint',
  Lpd_Lpr = 'lpd-lpr',
  Ipp = 'ipp',
  Ipps = 'ipps',
  Ws_Print = 'ws-print',
  PostScript_Security = 'ps-security',
  Verify_Certificate = 'verify-certificate',
  Enable_WINS_Port = 'wins-port',
  WINS_Registration = 'wins-registration',
  Csrf_Prevention = 'csrf-prevention',
  Dc_Ports = 'dc-ports',
  Host_Usb_Pnp = 'host-usb-pnp',
  Secure_Boot_Presence = 'secure-boot-presence',
  Intrusion_Detection_Presence = 'intrusion-detect-presence',
  Whitelisting_Presence = 'whitelist-presence',
  Fax_Receive = 'fax-receive',
  Ews_Access = 'ews-access',
  Info_Tab = 'info-tab',
  Firmware_Downgrade = 'fw-downgrade',
  Disk_Encryption_Status = 'disk-encryption',
  Firmware_Update = 'fwu',
  Control_Panel_Timeout = 'ctrl-panel-timeout',
  Web_Encryption = 'web-encryption',
  Asset_Number = 'asset-number',
  Company_Name = 'company-name',
  Contact_Person = 'contact-person',
  Device_Location = 'device-location',
  Device_Name = 'device-name',
  Duplex_Binding = 'duplex-binding',
  Tray_Admin = 'tray-admin',
  Control_Panel_Language = 'ctrl-panel-language',
  Home_Screen_App_Fs4 = 'home-screen-app-fs4',
  Home_Screen_App_Pro = 'home-screen-app-pro',
  Home_Screen_Custom = 'home-screen-custom',
  Home_Screen_Language = 'home-screen-language',
  Home_Screen_Wallpaper = 'home-screen-wallpaper',
  Date_Time_Format = 'date-time-format',
  Time_Zone = 'time-zone',
  Sleep_Delay = 'sleep-delay',
  Def_Media_Size = 'def-media-size',
  Def_Media_Type = 'def-media-type',
  Delay_Low_Supply_Msg = 'delay-low-supply-msg',
  Cartridge_Policy = 'cartridge-policy',
  Bt_Low_Energy = 'bt-low-energy',
  Dns_Server = 'dns-server',
  Ipv4_Info = 'ipv4-info',
  Ipv6_Info = 'ipv6-info',
  Link_Setting = 'link-setting',
  Airprint_Fax = 'airprint-fax',
  Airprint_Scan = 'airprint-scan', // to be replaced by web-scan
  Airprint_Secure_Scan = 'airprint-secure-scan', // to be replaced by web-scan
  Airprint_Scan_Secure_Scan = 'airprint-scan-secure-scan', // to be replaced by web-scan
  Web_Scan = 'web-scan',
  Dhcp_v4_Compliance = 'dhcp-v4-compliance',
  Ews_Language = 'ews-language',
  Time_Services = 'time-services',
  Device_User_Accounts = 'device-user-accounts', // ECP 0.55+
  Def_Print_Options = 'def-print-options',
  Input_Auto_Continue = 'input-auto-continue',
  Supplies_Status_Msgs_On_Ctrl_Panel = 'supplies-status-msgs-on-ctrl-panel',
  Input_Auto_Continue_Timeout = 'input-auto-continue-timeout',
  Save_to_SharePoint = 'save-to-share-point',
  Save_to_Email = 'save-to-email',
  Save_to_Network_Folder = 'save-to-network-folder',
  Supply_Low_Alerts = 'supply-low-alerts',
  Cartridge_Threshold_Black = 'cartridge-threshold-black',
  Cartridge_Threshold_Cyan = 'cartridge-threshold-cyan',
  Cartridge_Threshold_Magenta = 'cartridge-threshold-magenta',
  Cartridge_Threshold_Yellow = 'cartridge-threshold-yellow',
  Drum_Threshold_Black = 'drum-threshold-black',
  Drum_Threshold_Cyan = 'drum-threshold-cyan',
  Drum_Threshold_Magenta = 'drum-threshold-magenta',
  Drum_Threshold_Yellow = 'drum-threshold-yellow',
  Novell_Cfg = 'novell-cfg', // to remove (legacy)
  Telnet = 'telnet',
  Tftp_Cfg = 'tftp-cfg',
  JetDirect_Xml_Cfg = 'jd-xml-svc',
  Ftp_Print = 'ftp-print',
  Tcpip_Config = 'tcpip-config',
  Restrict_Color = 'restrict-color',
  Color_Usage_Log = 'color-usage-log',
  Digital_Sending = 'digital-sending',
  Ldap_Setup = 'ldap-setup',
  Device_Announcement = 'device-announcement',
  Use_Requested_Tray = 'use-requested-tray',
  Manual_Feed = 'manual-feed',
  Manual_Feed_Prompt = 'manual-feed-prompt',
  Override_Letter_A4 = 'override-letter-a4',
  Size_Type_Prompt = 'size-type-prompt',
  Auto_Send = 'auto-send',
  Online_Solutions = 'online-solutions',
  Outgoing_Servers = 'outgoing-servers',
  Show_Date_Time = 'show-date-time',
  Sleep_Settings = 'sleep-settings',
  Copy_Tray = 'copy-tray',
  Copy_Darkness = 'copy-darkness',
  Copy_Contrast = 'copy-contrast',
  Copy_Bg_Cleanup = 'copy-bg-cleanup',
  Copy_Sharpness = 'copy-sharpness',
  Copy_Optimize = 'copy-optimize',
  Enforce_Stamps = 'enforce-stamps',
  Smtp_Gateway = 'smtp-gateway', // to remove (not needed)
  Email_Message = 'email-message',
  Email_File = 'email-file',
  Email_Scan = 'email-scan',
  Email_Notification = 'email-notification',
  Network_Folder_File = 'network-folder-file',
  Network_Folder_Notification = 'network-folder-notification',
  Fax_Header = 'fax-header',
  Fax_Send = 'fax-send',
  PC_Fax_Send = 'pc-fax-send',
  Very_Low_Action_Black = 'very-low-action-black',
  Very_Low_Action_Color = 'very-low-action-color',
  System_Contact = 'system-contact',
  System_Location = 'system-location',
  Hp_Web_Svc = 'hp-web-svc',
  Hp_Jet_Adv = 'hp-jet-adv',
  Smart_Cloud_Print = 'smart-cloud-print',
  Proxy_Server = 'proxy-server',
  App_Deployment = 'app-deployment',
  Energy_Settings = 'energy-settings',
  Ca_Certificate = 'ca-certificate',
  Id_Certificate = 'id-certificate', // to remove (one-shot only)
  Print_Anywhere_Securely = 'print-anywhere-securely',
  CORS = 'cors',
  WiFi_Direct = 'wifi-direct',
  Sleep_Schedule = 'sleep-schedule',
  Google_Cloud_Print = 'google-cloud-print',
  Quick_Sets = 'quick-sets',
  IP_Fax = 'ip-fax',
  Support_Contact = 'support-contact',
  Configuration_Precedence = 'configuration-precedence',
  Pin_Protection = 'pin-protection',
  Copy_Job_Build = 'copy-job-build',
  Copy_Color_Mode = 'copy-color-mode',
  Cartridge_Protection = 'cartridge-protection',
  Consumable_Access_Ctrl = 'consumable-access-ctrl',
  Transfer_Kit_Threshold = 'transfer-kit-threshold',
  Fuser_Kit_Threshold = 'fuser-kit-threshold',
  Maintenance_Kit_Threshold = 'maintenance-kit-threshold',
  Very_Low_Action_Transfer_Kit = 'very-low-action-transfer-kit',
  Very_Low_Action_Fuser_Kit = 'very-low-action-fuser-kit',
  Very_Low_Action_Maintenance_Kit = 'very-low-action-maintenance-kit',
  Very_Low_Action_Doc_Feeder_Kit = 'very-low-action-doc-feeder-kit',
  Device_Access_Ctrl = 'device-access-ctrl',
  Bootloader_Password = 'bootloader-password',
  Auth_802_1X_Wired = '802-1x-auth',
  Auth_802_1X_Wireless = '802-1x-auth-wifi',
  System_Logging = 'system-logging',
  Guest_Access = 'guest-access',
  Certificate_Management_Service = 'certificate-mgt-svc',
  HTTPS = 'https',
  Cancel_Print_Jobs = 'cancel-print-jobs',
  Display_Job_Status = 'display-job-status',
  Erase_Data = 'erase-data',
  Extended_Signature_Verification = 'ext-signature-verification',
  NFC = 'nfc',
  Wireless_Radio_State = 'wifi-radio-state',
  Ftp_Server = 'ftp-server',
  Group_One_Pin = 'group-one-pin',
  Group_Two_Pin = 'group-two-pin',
  Ram_Disc_Cfg = 'ram-disk-cfg',
  POP3 = 'pop3',
  HP_Connection_Inspector = 'hp-connection',
  Sip_Server_Settings = 'sip-server',
  Fax_Speed_Dial_Lock = 'fax-speed-dial-lock',
  Secure_Disk_Password = 'disk-password',
  Email_Restriction = 'email-restriction',
  Job_Behavior = 'job-behavior',
  Control_Panel_Logout_Policy = 'ctrl-panel-logout-policy',
  Tpm_Status = 'tpm-status',
  Fips_140_Compliance = 'fips-140-compliance',
  IP_Security = 'ip-security',
  WiFi_Direct_Print = 'wifi-direct-print',
  Retain_Jobs_After_Reboot = 'retain-jobs-after-reboot',
  Temp_Job_Storage_Limit = 'job-storage-limit-temporary',
  Identity_Certificate = 'identity-certificate',
  Smb_Cifs = 'smb-cifs'
}

//Categories of the policy attributes
export enum PolicyCategoryEnum {
  Copier = 'copier',
  Device = 'device',
  DigitalSend = 'digital_send',
  Ews = 'ews',
  Fax = 'fax',
  FileSystem = 'file_system',
  Firmware = 'firmware',
  Network = 'network',
  Roam4B = 'roam4b',
  Security = 'security',
  Solutions = 'solutions',
  Supplies = 'supplies',
  WebServices = 'web_services',
  Wireless = 'wireless'
}

//Categories of the policy attributes
export default class PoliciesCategories {
  static catCopier: PolicyCategoryEnum[] = [PolicyCategoryEnum.Copier]
  static catDevice: PolicyCategoryEnum[] = [PolicyCategoryEnum.Device]
  static catDigitalSend: PolicyCategoryEnum[] = [PolicyCategoryEnum.DigitalSend]
  static catEws: PolicyCategoryEnum[] = [PolicyCategoryEnum.Ews]
  static catFax: PolicyCategoryEnum[] = [PolicyCategoryEnum.Fax]
  static catFileSystem: PolicyCategoryEnum[] = [PolicyCategoryEnum.FileSystem]
  static catFirmware: PolicyCategoryEnum[] = [PolicyCategoryEnum.Firmware]
  static catNetwork: PolicyCategoryEnum[] = [PolicyCategoryEnum.Network]
  static catRoam4B: PolicyCategoryEnum[] = [PolicyCategoryEnum.Roam4B]
  static catSecurity: PolicyCategoryEnum[] = [PolicyCategoryEnum.Security]
  static catSolutions: PolicyCategoryEnum[] = [PolicyCategoryEnum.Solutions]
  static catSupplies: PolicyCategoryEnum[] = [PolicyCategoryEnum.Supplies]
  static catWebServices: PolicyCategoryEnum[] = [PolicyCategoryEnum.WebServices]
  static catWireless: PolicyCategoryEnum[] = [PolicyCategoryEnum.Wireless]

  static attributeInfo: Record<string, PolicyCategoryEnum[]> = {
    [PolicyAttributeEnum.Restrict_Color]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Color_Usage_Log]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Digital_Sending]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Ldap_Setup]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Device_Announcement]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.EWS_Admin_Password]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.EWS_Admin_Password_New]:
      PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.SNMP_V1_V2]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.SNMP_V3]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.PJL_Password]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Remote_Cfg_Password]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Bootloader_Password]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Auth_802_1X_Wired]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Auth_802_1X_Wireless]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Svc_Access_Code]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Print_Anywhere_Securely]:
      PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.CORS]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Copy_Tray]: PoliciesCategories.catCopier,
    [PolicyAttributeEnum.Copy_Darkness]: PoliciesCategories.catCopier,
    [PolicyAttributeEnum.Copy_Contrast]: PoliciesCategories.catCopier,
    [PolicyAttributeEnum.Copy_Bg_Cleanup]: PoliciesCategories.catCopier,
    [PolicyAttributeEnum.Copy_Sharpness]: PoliciesCategories.catCopier,
    [PolicyAttributeEnum.Copy_Optimize]: PoliciesCategories.catCopier,
    [PolicyAttributeEnum.Enforce_Stamps]: PoliciesCategories.catCopier,
    [PolicyAttributeEnum.Svc_Location_Protocol]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.IPv4_Multicast]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.LLMNR]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Ws_Discovery]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Bonjour]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Tcpip_Print]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Airprint]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Lpd_Lpr]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Ipp]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Ipps]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Ws_Print]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Ftp_Print]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Google_Cloud_Print]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.File_Erase_Mode]: PoliciesCategories.catFileSystem,
    [PolicyAttributeEnum.Retain_Print_Jobs]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Control_Panel_Lock]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Control_Panel_Timeout]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Dc_Ports]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Host_Usb_Pnp]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Check_Latest_FW]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Secure_Boot_Presence]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Intrusion_Detection_Presence]:
      PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Whitelisting_Presence]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.PJL_Access_Commands]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.PostScript_Security]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.FS_Access_Protocols]: PoliciesCategories.catFileSystem,
    [PolicyAttributeEnum.Disk_Encryption_Status]:
      PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Fax_Header]: PoliciesCategories.catFax,
    [PolicyAttributeEnum.Fax_Send]: PoliciesCategories.catFax,
    [PolicyAttributeEnum.PC_Fax_Send]: PoliciesCategories.catFax,
    [PolicyAttributeEnum.Fax_Receive]: PoliciesCategories.catFax,
    [PolicyAttributeEnum.IP_Fax]: PoliciesCategories.catFax,
    [PolicyAttributeEnum.Verify_Certificate]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Enable_WINS_Port]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.WINS_Registration]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Csrf_Prevention]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Web_Encryption]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.HTTPS_Redirect]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Ews_Access]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Info_Tab]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Novell_Cfg]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Telnet]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Tftp_Cfg]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.JetDirect_Xml_Cfg]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Legacy_Firmware_Upgrade]:
      PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Remote_FW_Update]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Auto_FW_Update]: PoliciesCategories.catFirmware,
    [PolicyAttributeEnum.Firmware_Downgrade]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.WiFi]: PoliciesCategories.catRoam4B,
    [PolicyAttributeEnum.WiFi_Direct]: PoliciesCategories.catWireless,
    [PolicyAttributeEnum.Firmware_Update]: PoliciesCategories.catFirmware,
    [PolicyAttributeEnum.Asset_Number]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Company_Name]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Contact_Person]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Device_Location]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Device_Name]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Duplex_Binding]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Tray_Admin]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Control_Panel_Language]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Home_Screen_App_Fs4]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Home_Screen_App_Pro]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Home_Screen_Custom]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Home_Screen_Language]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Home_Screen_Wallpaper]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Use_Requested_Tray]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Manual_Feed]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Manual_Feed_Prompt]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Override_Letter_A4]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Size_Type_Prompt]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Auto_Send]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Online_Solutions]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Outgoing_Servers]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Show_Date_Time]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Date_Time_Format]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Time_Zone]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Sleep_Delay]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Sleep_Settings]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Energy_Settings]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Sleep_Schedule]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Def_Media_Size]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Def_Media_Type]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Quick_Sets]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.System_Logging]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Delay_Low_Supply_Msg]: PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Cartridge_Policy]: PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Bt_Low_Energy]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Dns_Server]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Ipv4_Info]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Ipv6_Info]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Configuration_Precedence]:
      PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Link_Setting]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Airprint_Fax]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Airprint_Scan]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Airprint_Secure_Scan]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Airprint_Scan_Secure_Scan]:
      PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Web_Scan]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Dhcp_v4_Compliance]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Support_Contact]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.System_Contact]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.System_Location]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Tcpip_Config]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Ca_Certificate]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Id_Certificate]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Ews_Language]: PoliciesCategories.catEws,
    [PolicyAttributeEnum.Time_Services]: PoliciesCategories.catEws,
    [PolicyAttributeEnum.Device_User_Accounts]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Input_Auto_Continue]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Def_Print_Options]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Supplies_Status_Msgs_On_Ctrl_Panel]:
      PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Input_Auto_Continue_Timeout]:
      PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Save_to_SharePoint]: PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Save_to_Email]: PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Save_to_Network_Folder]:
      PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Smtp_Gateway]: PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Email_Message]: PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Email_File]: PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Email_Scan]: PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Email_Notification]: PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Network_Folder_File]:
      PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Network_Folder_Notification]:
      PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Supply_Low_Alerts]: PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Cartridge_Threshold_Black]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Cartridge_Threshold_Cyan]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Cartridge_Threshold_Magenta]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Cartridge_Threshold_Yellow]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Drum_Threshold_Black]: PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Drum_Threshold_Cyan]: PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Drum_Threshold_Magenta]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Drum_Threshold_Yellow]: PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Very_Low_Action_Black]: PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Very_Low_Action_Color]: PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Hp_Web_Svc]: PoliciesCategories.catWebServices,
    [PolicyAttributeEnum.Hp_Jet_Adv]: PoliciesCategories.catWebServices,
    [PolicyAttributeEnum.Smart_Cloud_Print]: PoliciesCategories.catWebServices,
    [PolicyAttributeEnum.Proxy_Server]: PoliciesCategories.catWebServices,
    [PolicyAttributeEnum.App_Deployment]: PoliciesCategories.catSolutions,
    [PolicyAttributeEnum.Pin_Protection]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Copy_Job_Build]: PoliciesCategories.catCopier,
    [PolicyAttributeEnum.Copy_Color_Mode]: PoliciesCategories.catCopier,
    [PolicyAttributeEnum.Cartridge_Protection]: PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Consumable_Access_Ctrl]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Transfer_Kit_Threshold]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Fuser_Kit_Threshold]: PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Maintenance_Kit_Threshold]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Very_Low_Action_Transfer_Kit]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Very_Low_Action_Fuser_Kit]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Very_Low_Action_Maintenance_Kit]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Very_Low_Action_Doc_Feeder_Kit]:
      PoliciesCategories.catSupplies,
    [PolicyAttributeEnum.Device_Access_Ctrl]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Guest_Access]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Certificate_Management_Service]:
      PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.HTTPS]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Cancel_Print_Jobs]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Display_Job_Status]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Erase_Data]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Extended_Signature_Verification]:
      PoliciesCategories.catDevice,
    [PolicyAttributeEnum.NFC]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Wireless_Radio_State]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Ftp_Server]: PoliciesCategories.catCopier,
    [PolicyAttributeEnum.Group_One_Pin]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Group_Two_Pin]: PoliciesCategories.catSecurity,
    [PolicyAttributeEnum.Ram_Disc_Cfg]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.POP3]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.HP_Connection_Inspector]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Sip_Server_Settings]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Fax_Speed_Dial_Lock]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Secure_Disk_Password]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Email_Restriction]: PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Job_Behavior]: PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Control_Panel_Logout_Policy]:
      PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Tpm_Status]: PoliciesCategories.catDigitalSend,
    [PolicyAttributeEnum.Fips_140_Compliance]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.IP_Security]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.WiFi_Direct_Print]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Temp_Job_Storage_Limit]: PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Identity_Certificate]: PoliciesCategories.catNetwork,
    [PolicyAttributeEnum.Retain_Jobs_After_Reboot]:
      PoliciesCategories.catDevice,
    [PolicyAttributeEnum.Smb_Cifs]: PoliciesCategories.catSecurity
  }

  static getPolicyAttributeCategory(
    id: PolicyAttributeEnum | string
  ): PolicyCategoryEnum[] {
    return PoliciesCategories.attributeInfo[id] || []
  }

  // Returns policy attribute sub-categories
  static getPolicyCategories(
    startsWith: PolicyCategoryEnum[],
    attributes: { name: PolicyAttributeEnum | string }[]
  ): PolicyCategoryEnum[] {
    const categories = []
    attributes.forEach((attribute) => {
      const policyCategory: PolicyCategoryEnum[] =
        PoliciesCategories.getPolicyAttributeCategory(attribute.name)
      if (
        policyCategory &&
        policyCategory.length > startsWith.length &&
        startsWith.every((cat, i) => cat === policyCategory[i]) &&
        !categories.includes(policyCategory[startsWith.length])
      ) {
        categories.push(policyCategory[startsWith.length])
      }
    })

    if (categories.length) {
      const sortedCategories = []
      for (const category in PolicyCategoryEnum) {
        if (categories.includes(PolicyCategoryEnum[category])) {
          sortedCategories.push(PolicyCategoryEnum[category])
        }
      }
      return sortedCategories
    }
    return []
  }
}
